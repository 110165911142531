import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulSurgeriesPage {
        heroTitle
        heroSubTitle
        heroContent {
          heroContent
        }
        heroLabel
        heroUrl
        heroImage {
          file {
            url
          }
        }
      }
    }
  `)
  const { heroTitle, heroUrl, heroSubTitle, heroContent, heroLabel, heroImage } =
    data.contentfulSurgeriesPage
  return (
    <section className="service-p-hero surgery-p-hero">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-md-6 d-flex align-items-center">
            <div>
              <h6>{heroTitle}</h6>
              <h2>{heroSubTitle}</h2>
              <p>{heroContent.heroContent}</p>
              <Link to={heroUrl} className="d-btn">
                {heroLabel}
              </Link>
            </div>
          </div>
          <div className="col col-md-6">
            <img className="w-100" src={heroImage.file.url} alt="#" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
